<template>
  <page-main
    title="Let's get started by filling in some basic information"
    aside="Just the basics"
    nextText="Address and Contact Information"
    buttonTabIndex="6"
    eventName="nav"
    @nav="nav('/Address')"
    @save="save()"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="title"
            label="Title"
            tabindex="1"
            autofocus
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="firstName"
            label="First Name"
            tabindex="2"
            :rules="[rules.required]"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="middleName"
            label="Middle Name (or initial)"
            tabindex="3"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="lastName"
            label="Last Name"
            tabindex="4"
            :rules="[rules.required]"
          ></r-text-field>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="suffix"
            label="Suffix"
            tabindex="5"
          ></r-text-field>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { required } from "../utility/validation.js";

// @ is an alias to /src
export default {
  name: "Information",
  data: () => ({
    rules: {
      required: value => required(value)
    }
  }),
  components: {},
  methods: {
    save() {
      this.computeName();
      this.saveName();
    },
    ...mapActions("name", ["saveName"]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    computeName() {
      let displayName =
        "" +
        (this.title.length > 0 ? this.title + " " : "") +
        (this.firstName.length > 0 ? this.firstName + " " : "") +
        (this.middleName.length > 0 ? this.middleName + " " : "") +
        (this.lastName.length > 0 ? this.lastName : "") +
        (this.suffix.length > 0 ? ", " + this.suffix : "");

      this.displayValue = displayName;

      return [displayName];
    }
  },
  mounted() {},
  computed: {
    ...mapFields("name", [
      "displayValue",
      "title",
      "firstName",
      "middleName",
      "lastName",
      "suffix"
    ]),
    nameDisplay() {
      return this.computeName();
    }
  }
};
</script>
